export var DeliveryMessageType;
(function (DeliveryMessageType) {
    DeliveryMessageType["Free"] = "free";
    DeliveryMessageType["Undefined"] = "undefined";
    DeliveryMessageType["Defined"] = "defined";
})(DeliveryMessageType || (DeliveryMessageType = {}));
export var Step;
(function (Step) {
    Step["Cart"] = "cart";
    Step["Checkout"] = "checkout";
    Step["ThankYou"] = "thankYou";
})(Step || (Step = {}));
export var GtmAddressEvents;
(function (GtmAddressEvents) {
    GtmAddressEvents["AutocompleteAddress"] = "autocompleteAddress";
    GtmAddressEvents["RedAddress"] = "redAddress";
    GtmAddressEvents["MapAddress"] = "mapAddress";
})(GtmAddressEvents || (GtmAddressEvents = {}));
