/**
 * function for validate phone number
 * @param phone - phone number in format (+x (xxx) xx-xx-xx | xxxxxxxxxxx)
 */
export function validatePhone(phone) {
    return phone ? !(/^\+7\s*?\(?(7|8)+\d*?/).test(phone) : true;
}
/**
 * function deletes 7 or 8 if the phone number starts with 78 or 77
 * @param phone - phone number in format (+x (xxx) xx-xx-xx | xxxxxxxxxxx)
 * @returns new number without 7 or 8 if it falls under the conditions
 */
export function formatPhone(phone) {
    if (!validatePhone(phone))
        return phone.replace(/7|8/g, '');
    return phone;
}
