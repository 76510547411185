export const HEADER_KEY = 'x-cart-id';
export const PARAMS_KEY = '_cart_id';
export function getCartId() {
    let cartId;
    try {
        cartId = localStorage.getItem('shoppingCartId');
    }
    catch (_a) {
        cartId = getCartIdFromMeta();
    }
    return cartId;
}
export function getCartIdFromMeta() {
    var _a;
    return (_a = document.querySelector(`meta[name=${HEADER_KEY}]`)) === null || _a === void 0 ? void 0 : _a.getAttribute('content');
}
export function setCartId(cartId) {
    var _a;
    try {
        setCurrentUrl(cartId);
        localStorage.setItem('shoppingCartId', cartId !== null && cartId !== void 0 ? cartId : '');
    }
    catch (_b) {
        (_a = document.querySelector(`meta[name=${HEADER_KEY}]`)) === null || _a === void 0 ? void 0 : _a.setAttribute('content', cartId !== null && cartId !== void 0 ? cartId : '');
    }
}
export function isStoreFallbackState() {
    return !navigator.cookieEnabled;
}
export function prepareUrl(href) {
    if (!(href && isSuitableUrl(href)))
        return;
    const cartId = getCartId();
    if (!cartId)
        return;
    href += href.indexOf('?') > -1 ? `&${PARAMS_KEY}=${cartId}` : `?${PARAMS_KEY}=${cartId}`;
    return href;
}
function isSuitableUrl(url) {
    // already added
    if (new RegExp(PARAMS_KEY).test(url))
        return false;
    // relative
    if (/^\//.test(url))
        return true;
    // development
    if (/localhost/.test(window.location.host))
        return true;
    // match domain
    const parts = window.location.host.split('.');
    const domain = parts[parts.length - 2];
    return (new RegExp(`\.${domain}\.`).test(url));
}
function setCurrentUrl(cartId) {
    try {
        const url = new URL(window.location.href);
        if (url.searchParams.get(PARAMS_KEY) === cartId)
            return;
        if (cartId) {
            url.searchParams.set(PARAMS_KEY, cartId);
        }
        else {
            url.searchParams.delete(PARAMS_KEY);
        }
        history.replaceState('', '', url.toString());
    }
    catch (_a) { }
}
