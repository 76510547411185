import cond from 'core/utils/cond'

const HUMAN_TYPES = {
  days: ['день', 'дня', 'дней'],
  hours: ['час', 'часа', 'часов'],
  minutes: ['минута', 'минуты', 'минут'],
  seconds: ['секунда', 'секунды', 'секунд']
}

export default function TimerWidget($widget, {
  callbacks: {
    onExpired
  },

  ...props
}) {
  let __intervalId = null

  props = {
    expireAt: null,
    needPad: false,
    ...props
  }

  let calcTime = () => {
    let time = Date.parse(props.expireAt) - Date.parse(new Date())

    return {
      days: Math.floor((time / (1000 * 60 * 60 * 24))),
      hours: Math.floor((time / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((time / 1000 / 60) % 60),
      seconds: Math.floor((time / 1000) % 60)
    }
  }

  let render = time => requestAnimationFrame(() => {
    Object.entries(time)
      .map(([type, value]) => {
        return [type, value, buildHumanType(type, value)]
      })
      .map(([type, value, humanType]) => {
        return props.needPad ?
          [type, value.toString().padStart(2, '0'), humanType] :
          [type, value.toString(), humanType]
      })
      .map(([type, value, humanType]) => {
        return [
          type,
          value,
          value.split('').map(n => `<span>${n}</span>`),
          humanType
        ]
      })
      .forEach(([type, value, valueHtml, humanType]) => {
        $widget.find(`%{w-Timer/${type}}`)
          .attr({
            'data-value': value,
            'data-human-type': humanType
          })
          .html(valueHtml)
      })
  })

  let buildHumanType = (type, value) => {
    let words = HUMAN_TYPES[type]

    return cond(
      when => value % 100 > 4 && value % 100 < 20, to => words[2],
      when => value % 10 === 0, to => words[2],
      when => value % 10 > 4, to => words[2],
      when => value % 10 > 1 && value % 10 < 5, to => words[1],
      when => value % 10 === 1, to => words[0],
    )
  }

  let handleTick = () => {
    let time = calcTime()
    let isExpired = Object.entries(time).some(([_type, value]) => value < 0)

    if (isExpired) {
      return handleExpired()
    }

    render(time)
  }

  let handleExpired = () => {
    clearInterval(__intervalId)

    onExpired()
  }

  return {
    connect: () => {
      if (props.expireAt) {
        __intervalId = setInterval(handleTick, 1000)

        handleTick()
      }
    },

    disconnect: () => {
      clearInterval(__intervalId)
    }
  }
}
