// yandex maps api: https://yandex.ru/dev/maps/jsapi/doc/2.1/ref/concepts/About.html
import { pushToDataLayer } from 'core/services/GTM';
import { Component, h } from 'preact';
import generateId from 'core/utils/generateId';
import htmlClassNames from 'core/utils/htmlClassNames';
import initYmapScript from 'site/VControllers/components/Checkout/services/initYmapScript';
import getCityCenter from 'site/VControllers/components/Checkout/api/getCityCenter';
var CoordsType;
(function (CoordsType) {
    CoordsType["StreetAddress"] = "street_address";
    CoordsType["City"] = "city";
})(CoordsType || (CoordsType = {}));
export class AddressMap extends Component {
    constructor() {
        super(...arguments);
        this.state = { isPlacemarked: false };
        this.mapId = `yandex_map_${generateId()}`;
        this.placemark = null;
        this.initYmaps = () => {
            window.ymaps.ready(this.setupMap);
        };
        this.findCoordinates = async () => {
            var _a;
            const { field: { options: { yandexMapApiKey }, value: { cityName, coordinates = null } }, onChange } = this.props;
            if ((coordinates === null || coordinates === void 0 ? void 0 : coordinates.type) === CoordsType.StreetAddress) {
                return coordinates.point;
            }
            if (((_a = Env.abTesting) === null || _a === void 0 ? void 0 : _a.cartDetectCoordTest) && window.navigator.geolocation) {
                window.navigator.geolocation.getCurrentPosition(({ coords }) => {
                    pushToDataLayer({ event: 'geo-request-accept' });
                    let addressCoords = [coords.latitude, coords.longitude];
                    this.setPlacemark(addressCoords);
                    onChange(addressCoords);
                }, () => {
                    pushToDataLayer({ event: 'geo-request-decline' });
                });
            }
            if (coordinates === null || coordinates === void 0 ? void 0 : coordinates.point) {
                return coordinates.point;
            }
            else {
                return await getCityCenter(yandexMapApiKey, cityName);
            }
        };
        this.setupMap = async () => {
            const { field: { value: { zoom, coordinates = null } }, onChange } = this.props;
            let center = await this.findCoordinates();
            this.map = new window.ymaps.Map(this.mapId, {
                center,
                zoom,
                controls: ['zoomControl', 'geolocationControl']
            }, {
                yandexMapDisablePoiInteractivity: true,
                suppressMapOpenBlock: true
            });
            this.updateAddressPoint(coordinates, zoom);
            this.map.events.add('click', (e) => {
                const addressCoords = e.get('coords');
                this.setPlacemark(addressCoords);
                onChange(addressCoords);
            });
        };
        this.setPlacemark = (coords) => {
            this.placemark = new window.ymaps.Placemark(coords, {}, {
                preset: 'islands#redDotIcon'
            });
            this.map.geoObjects.removeAll();
            this.map.geoObjects.add(this.placemark);
            this.setState({ isPlacemarked: true });
        };
        this.removePlacemark = () => {
            this.placemark = null;
            this.map.geoObjects.removeAll();
            this.setState({ isPlacemarked: false });
        };
        this.updateAddressPoint = (coords, zoom) => {
            var _a;
            const { isPlacemarked } = this.state;
            if (!coords)
                return;
            this.map.setCenter(coords.point, zoom);
            switch (coords.type) {
                case CoordsType.City:
                    this.removePlacemark();
                    break;
                case CoordsType.StreetAddress:
                    if (!isPlacemarked)
                        this.setPlacemark(coords.point);
                    (_a = this.placemark.geometry) === null || _a === void 0 ? void 0 : _a.setCoordinates(coords.point);
                    break;
            }
        };
    }
    componentDidMount() {
        const { field: { options: { yandexMapApiKey } } } = this.props;
        initYmapScript(yandexMapApiKey, this.initYmaps);
    }
    componentDidUpdate({ field: { value: { coordinates: prevCoords } } }) {
        const { coordinates, zoom } = this.props.field.value;
        if (!this.map || !coordinates || (prevCoords === null || prevCoords === void 0 ? void 0 : prevCoords.point.toString()) === (coordinates === null || coordinates === void 0 ? void 0 : coordinates.point.toString()))
            return;
        this.updateAddressPoint(coordinates, zoom);
    }
    render() {
        if (Env.name === 'test')
            return h("div", null);
        return (h("div", { className: htmlClassNames('b-Ymap_map', { '__disabled': this.props.disabled }) },
            h("div", { className: 'b-Ymap_mapWrap', id: this.mapId })));
    }
}
