export var FieldsKeys;
(function (FieldsKeys) {
    FieldsKeys["FirstName"] = "first_name";
    FieldsKeys["LastName"] = "last_name";
    FieldsKeys["Phone"] = "phone";
    FieldsKeys["Email"] = "email";
    FieldsKeys["Country"] = "country_id";
    FieldsKeys["City"] = "city_id";
    FieldsKeys["Address"] = "address";
    FieldsKeys["Comment"] = "comment";
    FieldsKeys["StreetAddress"] = "street_address_id";
    FieldsKeys["Apartment"] = "apartment";
    FieldsKeys["ZipCode"] = "zip_code";
    FieldsKeys["PaymentMethod"] = "payment_method";
    FieldsKeys["DeliveryMethod"] = "delivery_type";
    FieldsKeys["Terminal"] = "dl_terminal_id";
    FieldsKeys["Ozon"] = "ozon_map";
    FieldsKeys["Yandex"] = "yandex_map";
    FieldsKeys["InlineAddress"] = "inline_address";
    FieldsKeys["AddressMap"] = "address_map";
    FieldsKeys["CustomerType"] = "customer_type";
    FieldsKeys["Inn"] = "inn";
})(FieldsKeys || (FieldsKeys = {}));
export const BUYER_GROUP_KEY = 'buyer';
export var DeliveryMethod;
(function (DeliveryMethod) {
    DeliveryMethod["Delivery"] = "delivery";
    DeliveryMethod["TerminalDelivery"] = "terminal_delivery";
    DeliveryMethod["TerminalDeliveryKit"] = "terminal_delivery_kit";
    DeliveryMethod["YandexDelivery"] = "yandex_delivery";
    DeliveryMethod["Pickup"] = "pickup";
    DeliveryMethod["ImrPickup"] = "imr_pickup";
})(DeliveryMethod || (DeliveryMethod = {}));
