const DAYS = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];
const buildSchedule = (schedule) => {
    return schedule.restrictions.map(({ days, time_from, time_to }) => {
        const period = days.length === 1 ? DAYS[days[0] - 1] : DAYS[days[0] - 1] + ' - ' + DAYS[days[days.length - 1] - 1];
        return `<span>${period}: с ${time_from.hours}:${time_from.minutes} по ${time_to.hours}:${time_to.minutes}</span>`;
    }).join('<br>');
};
export default function getData(points) {
    return {
        type: 'FeatureCollection',
        features: points.map(point => {
            return {
                type: 'Feature',
                id: point.id,
                address: {
                    fullAddress: point.address.full_address
                },
                geometry: {
                    type: 'Point',
                    coordinates: [point.position.latitude, point.position.longitude]
                },
                properties: {
                    pickupPointId: point.id,
                    fullAddress: point.address.full_address,
                    balloonContentHeader: `<h4>${point.name}</h4>`,
                    balloonContentBody: `<p>Адрес: ${point.address.full_address}<p><p>График работы:</p>${buildSchedule(point.schedule)}`,
                    clusterCaption: `<p>${point.name}</p>`
                }
            };
        })
    };
}
