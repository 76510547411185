import { sanitizeForRegexp } from 'core/utils/sanitize';
import { fetchAddresses } from './fetchAddresses';
export async function defineCityId(cityId, address) {
    const possibleSuggestions = await lookupData(address);
    let possibleCity;
    let score = 0;
    possibleSuggestions.reduce((result, item) => {
        if (!item.searchText || result[item.id.toString()])
            return result;
        let lastCoincidence;
        const amountCoincidence = item.searchText.split(', ').reduce((acc, part) => {
            part.split(' ').forEach(part => {
                const matchRes = address.match(new RegExp(`(${sanitizeForRegexp(part)})`, 'i'));
                if (!matchRes)
                    return acc;
                lastCoincidence = matchRes[0];
                return acc++;
            });
            return acc;
        }, 0);
        if (!lastCoincidence)
            return result;
        const newAddress = address.replace(new RegExp(`^.*${sanitizeForRegexp(lastCoincidence)}(\,)? `, 'i'), item.searchText + ', ');
        result[item.id.toString()] = true;
        if (amountCoincidence > score) {
            possibleCity = { id: item.id.toString(), address: newAddress };
            score = amountCoincidence;
        }
        return result;
    }, {});
    if (!possibleCity)
        return;
    if (cityId !== possibleCity.id)
        return possibleCity;
}
async function lookupData(query) {
    const possibleSuggestions = (await fetchAddresses({ query })).data;
    if (possibleSuggestions.length > 0)
        return possibleSuggestions;
    const parts = query.split(/\s+/);
    if (parts.length <= 2)
        return [];
    return (await lookupData(parts.slice(0, parts.length - 2).join(' ')));
}
