var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { h, render } from 'preact';
import { VController, register } from 'core/services/Controller';
import { loadComponentsStyles } from 'core/utils/asyncLoadAssets';
import ScrollLock from 'core/utils/scrollLock';
import { Modal } from './components/Modal';
import { isStoreFallbackState, prepareUrl } from 'site/VControllers/components/Checkout/services/statefullCart';
import { getDomainWithoutSubdomain } from 'core/utils/url';
const DEFAULT_SHOW_CART_HANDLER = (cartPath = '/cart') => {
    const url = Env.name === 'production' || Env.name === 'stage' ? `https://www.${getDomainWithoutSubdomain(window.location.href)}${cartPath}` : cartPath;
    window.location.href = prepareCartUrl(url);
};
let handleShowCart = DEFAULT_SHOW_CART_HANDLER;
let default_1 = class extends VController {
    constructor() {
        super(...arguments);
        this.destroyCart = () => {
            render(null, document.body, this.rootComponentEl);
            ScrollLock.disable();
            window.history.back();
        };
        this.handleRenderCart = (e) => {
            e.stopPropagation();
            e.preventDefault();
            this.renderCart(this.props.cartPath);
        };
        this.renderCart = async (path = '/cart') => {
            await loadComponentsStyles('SinglePageCheckoutFrontInit');
            setTimeout(() => {
                ScrollLock.enable();
                this.rootComponentEl = render(h(Modal, { onClose: this.destroyCart }), document.body);
            });
            const cartPath = prepareCartUrl(path);
            if (window.location.pathname !== cartPath)
                window.history.pushState(null, '', cartPath);
        };
    }
    init() {
        handleShowCart = (cartPath) => this.renderCart(cartPath);
        this.el.addEventListener('click', this.handleRenderCart);
    }
    destroy() {
        handleShowCart = DEFAULT_SHOW_CART_HANDLER;
        this.el.removeEventListener('click', this.handleRenderCart);
    }
};
default_1 = __decorate([
    register('SinglePageCheckoutFrontInit')
], default_1);
export default default_1;
export function showProductCart(cartPath) {
    handleShowCart(cartPath);
}
function prepareCartUrl(url) {
    if (!isStoreFallbackState())
        return url;
    const newUrl = prepareUrl(url);
    return newUrl ? newUrl : url;
}
