import { Component } from 'preact';
import h from '../../cssScope';
import { asyncComponent } from 'core/components/Hoc/asyncComponent';
import htmlClassNames from 'core/utils/htmlClassNames';
import { loadCartData } from 'site/VControllers/components/Checkout/api/checkout';
const CheckoutDesk = asyncComponent(() => import(/* webpackChunkName: "desktop_c-SinglePageCheckoutV2" */ '../App'));
const CheckoutMobile = asyncComponent(() => import(
/* webpackChunkName: "mobile_c-SinglePageCheckoutV2" */
'site.mobile/VControllers/SinglePageCheckout/v2/components/App'));
export class Layout extends Component {
    constructor() {
        super(...arguments);
        this.state = { loading: true };
        this.handleLoadingFinish = () => this.setState({ loading: false });
    }
    async componentWillMount() {
        try {
            const { data: { state } } = await loadCartData();
            this.setState({ state });
        }
        catch (e) {
            console.log(e);
            this.handleLoadingFinish();
        }
    }
    render() {
        const { loading, state } = this.state;
        const Checkout = Env.version === 'mobile' ? CheckoutMobile : CheckoutDesk;
        return (h("div", { className: 'sb-Layout g-Container__fluid' },
            h("a", { className: 'sb-Layout_logo', href: '/products' }),
            h("div", { className: 'sb-Layout_close', onClick: this.props.onClose },
                h("span", null)),
            h("div", { className: htmlClassNames('sb-Layout_splash', { '__active': loading }) }),
            state && (h("div", { className: 'sb-Layout_content' },
                h(Checkout, { initState: state, onLoaded: this.handleLoadingFinish, onReloaded: () => this.setState({ loading: true }), callToActionsProps: { onLoaded: this.handleLoadingFinish, btnClass: '__large __black' } })))));
    }
}
