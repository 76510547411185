import { FormNoticeService } from 'core/services/FormNoticeService';
import NoticeService from 'core/services/NoticeService';
import ValidationErrors from 'core/services/ValidationErrors';
import { HEADER_KEY, isStoreFallbackState, setCartId } from 'site/VControllers/components/Checkout/services/statefullCart';
import { $errors } from 'luxury_theme.mobile/stores/validations/errors';
function clearFlash(response) {
    const { data: { flash, errors } } = response;
    if (flash || errors)
        NoticeService.clear();
}
function showFlash(response) {
    const { data: { flash } } = response;
    if (flash)
        NoticeService.parse(flash);
}
function redirectHandler(response) {
    const { data: { redirectTo } } = response;
    if (redirectTo)
        window.location.href = redirectTo;
}
function validationErrors(response) {
    let { data: { errors } } = response;
    if (Array.isArray(errors)) {
        errors = errors.reduce((res, error) => {
            res[error.name] = error.errors;
            return res;
        }, {});
    }
    if (errors)
        ValidationErrors.build(errors);
}
function clearFormErrors() {
    $errors.set([]);
    FormNoticeService.clear();
}
function validateFormErrors(response) {
    let { data: { errors } } = response;
    if (errors && typeof errors === 'object') {
        const errorsFormatted = [];
        for (const [field, errorMessages] of Object.entries(errors)) {
            for (const message of errorMessages) {
                errorsFormatted.push({ field, message });
            }
        }
        $errors.set(errorsFormatted);
    }
    if (Array.isArray(errors)) {
        errors = errors.reduce((res, error) => {
            res[error.name] = error.errors;
            if (error.nested) {
                Object.keys(error.nested).forEach(id => {
                    error.nested[id].forEach((field) => {
                        res[`${error.name}.nested.${id}.${field.name}`] = field.errors;
                    });
                });
            }
            return res;
        }, {});
    }
    if (errors)
        FormNoticeService.errors(errors);
}
function storeCartId(response) {
    if (!(isStoreFallbackState() && response.headers['x-cart-info'] === '1'))
        return;
    const cartId = response.headers[HEADER_KEY];
    setCartId(cartId);
}
export const successHandlers = [storeCartId, clearFormErrors, clearFlash, showFlash, redirectHandler];
export const failHandlers = [storeCartId, clearFlash, showFlash, validationErrors];
export const failFormHandlers = [storeCartId, clearFormErrors, validateFormErrors];
