import Tooltip from 'core/components/UI/Tooltip';
import htmlClassNames from 'core/utils/htmlClassNames';
import { Component } from 'preact';
import { baseAutocomplete } from 'site/VControllers/Autocomplete/components/Hoc/baseAutocomplete';
import { GtmAddressEvents } from 'site/VControllers/components/Checkout/constants';
import { OPERATION_CHANGE_GTM_ADDRESS_EVENT } from 'site/VControllers/components/Checkout/operations/gtm';
import { defineCityId } from 'site/VControllers/SinglePageCheckout/services/defineCityId';
import { fetchAddresses, INLINE_ADDRESS_ID_REGEXP } from 'site/VControllers/SinglePageCheckout/services/fetchAddresses';
import { FieldsKeys } from '../../..';
import h from '../../../cssScope';
const AddressAutocomplete = baseAutocomplete('', 'Адрес', null);
const BASE_ID_REGEX = /^\d{13,}$/;
export class InlineAddress extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            inputRef: undefined
        };
        this.awaitCityId = null;
        this.awaitImproveAddress = false;
        this.handleInputRef = (el) => {
            this.setState({ inputRef: el });
        };
        this.handleOnInputRef = (el) => {
            var _a, _b;
            this.handleFocusOnLoad(el);
            (_b = (_a = this.props).onRef) === null || _b === void 0 ? void 0 : _b.call(_a, el);
            this.virtualInputRef = el;
        };
        this.handleFocusOnLoad = (el) => {
            if (this.props.focusOnLoad && el) {
                setTimeout(() => {
                    el.scrollIntoView({ behavior: 'smooth' });
                    el.focus();
                }, 1000);
            }
        };
        // to allow commit input address
        this.handleChangeSearch = (input) => {
            var _a, _b;
            this.awaitImproveAddress = true;
            (_b = (_a = this.props).onChangeValue) === null || _b === void 0 ? void 0 : _b.call(_a, FieldsKeys.Address, input);
        };
        this.storeImproveChange = (item) => {
            const itemId = parseItemId(item);
            this.awaitCityId = itemId !== null && itemId !== void 0 ? itemId : null;
        };
        this.saveSelectedCityId = (item) => {
            var _a, _b;
            const itemId = parseItemId(item);
            if (!(itemId && this.awaitCityId)) {
                if (this.awaitImproveAddress && !this.props.disabled)
                    setTimeout(this.compareAndSelectCityForUser, 100);
                this.awaitImproveAddress = false;
                return;
            }
            (_b = (_a = this.props).onChangeValue) === null || _b === void 0 ? void 0 : _b.call(_a, FieldsKeys.City, this.awaitCityId);
            this.awaitCityId = null;
        };
        this.handleFinalBlur = (item) => {
            this.saveSelectedCityId(item);
        };
        // we need change city for user to show real info about delivery
        this.compareAndSelectCityForUser = async () => {
            var _a, _b;
            const { formFields, field } = this.props;
            const addressField = formFields.find(field => field.key === FieldsKeys.Address);
            const cityField = formFields.find(field => field.key === FieldsKeys.City);
            if (!(addressField && cityField))
                return;
            // if an user doesn't change a city by autocomplete we know that city can be found in loaded from backend cities 
            const cityItem = cityField.items.find(item => item.id === cityField.value);
            // if we cannot find the city then user have changed city 
            if (!cityItem)
                return;
            const regexp = new RegExp(`${cityItem.text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}`, 'i');
            const address = addressField.value.toString();
            // if value exists then everything is ok
            if (!address || regexp.test(address))
                return;
            const defineResult = await defineCityId(cityItem.id.toString(), address);
            if (!defineResult)
                return;
            const parsedId = parseItemId({ id: defineResult.id, text: '' });
            if (parsedId) {
                field.value ? setTimeout(() => this.saveSearchValue(defineResult.address), 500) : this.saveSearchValue(defineResult.address);
                (_b = (_a = this.props).onChangeValue) === null || _b === void 0 ? void 0 : _b.call(_a, FieldsKeys.City, parsedId);
                this.props.onChange(null);
            }
        };
        this.saveSearchValue = (input) => {
            const inputRef = this.state.inputRef || this.virtualInputRef;
            if (!inputRef)
                return;
            inputRef.value = input;
            // we need to trigger update state from native event. to show user changed information
            // Current architecture of baseAutocomplete doesn't allow to pass searchValue from parent component
            inputRef.dispatchEvent(new Event('input'));
        };
        this.handleChange = (item, prevItem) => {
            var _a, _b, _c;
            (_b = (_a = this.context) === null || _a === void 0 ? void 0 : _a.store) === null || _b === void 0 ? void 0 : _b.operate(OPERATION_CHANGE_GTM_ADDRESS_EVENT, GtmAddressEvents.AutocompleteAddress);
            if (item.id === prevItem.id)
                return;
            if (this.state.inputRef)
                this.handleChangeSearch((_c = item.searchText) !== null && _c !== void 0 ? _c : item.text);
            this.awaitCityId = null;
            this.props.onChange(item.id);
        };
    }
    get isCountryBlank() {
        const countryField = this.props.formFields.find(field => field.key === FieldsKeys.Country);
        if (!countryField)
            return false;
        return !countryField.value;
    }
    get defaultSearchValue() {
        var _a;
        const { items, value } = this.props.field;
        if (items.length === 1 && !value && (!items[0].children || items[0].children.length === 0)) {
            // preselected city name
            return `${(_a = items[0].searchText) !== null && _a !== void 0 ? _a : items[0].text}, `;
        }
        return undefined;
    }
    get currentSavedAddressText() {
        return buildCurrentSavedAddressText(this.props.field);
    }
    render() {
        var _a, _b;
        const { field, name, disabled, useHidden, ...autocompleteProps } = this.props;
        const { inputRef } = this.state;
        const defaultSearchValue = this.defaultSearchValue;
        const isMobile = Env.version === 'mobile';
        const notice = (_b = (_a = this.context) === null || _a === void 0 ? void 0 : _a.store) === null || _b === void 0 ? void 0 : _b.getState().notices[FieldsKeys.InlineAddress];
        return (h("div", null,
            this.currentSavedAddressText ? (h("p", { className: 'sb-Row_itemHint' },
                "\u0412\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0439 \u0430\u0434\u0440\u0435\u0441: ",
                h("strong", null, this.currentSavedAddressText))) : (h("p", { className: 'sb-Row_itemHint' }, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0430\u0434\u0440\u0435\u0441 \u043D\u0430 \u043A\u0430\u0440\u0442\u0435 \u0438\u043B\u0438 \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u0443\u0439\u0442\u0435 \u043F\u043E\u0438\u0441\u043A")),
            h("div", { className: htmlClassNames('ui-Input', { '__text': !isMobile, [`__${notice === null || notice === void 0 ? void 0 : notice.type}`]: !!notice }) },
                useHidden && h("input", { ref: this.handleInputRef, name: name, type: 'text', value: field.value }),
                (!useHidden || inputRef) && (h(AddressAutocomplete, Object.assign({ isRequired: field.isRequired, inputEl: inputRef, disabled: disabled || this.isCountryBlank, value: field.value, label: field.label, defaultSearchValue: defaultSearchValue, placeholder: defaultSearchValue }, autocompleteProps, { items: field.items, onChange: this.handleChange, fetchItems: fetchAddresses, onChangeSearch: this.handleChangeSearch, onInputRef: this.handleOnInputRef, onImproveChange: this.storeImproveChange, onFinalBlur: this.handleFinalBlur, tag: isMobile ? 'textarea' : 'input', notice: notice, alwaysDisplaySearchValue: true, isSearchInput: true, autoSelect: true }))),
                this.isCountryBlank && h(Tooltip, { label: '\u0421\u043D\u0430\u0447\u0430\u043B\u0430 \u0432\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0441\u0442\u0440\u0430\u043D\u0443' }))));
    }
}
function parseItemId(item) {
    var _a;
    const itemId = (_a = item.id) === null || _a === void 0 ? void 0 : _a.toString();
    const matchRes = itemId === null || itemId === void 0 ? void 0 : itemId.match(INLINE_ADDRESS_ID_REGEXP);
    let cityId;
    if (matchRes) {
        cityId = matchRes[1];
    }
    if (!cityId && itemId && BASE_ID_REGEX.test(itemId))
        cityId = itemId;
    return cityId;
}
function buildCurrentSavedAddressText(field) {
    if (!field.value)
        return null;
    let text = null;
    const recurse = (items) => {
        items.forEach(item => {
            if (item.id === field.value) {
                text = item.searchText;
                return;
            }
            if (item.children) {
                recurse(item.children);
            }
        });
    };
    recurse(field.items);
    return text;
}
